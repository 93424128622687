import './linePage.scss'

const LinePage = () => {
  return (
    <>
        <div className="footer-bottom">
            <p className="footer-copy">©LoginSmart. tous droits réservés</p>
        </div>
    </>
  )
}

export default LinePage